import React, { useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Lightbox from 'react-image-lightbox';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import Button from '@mui/material/Button';

import Grid from '@mui/material/Grid';
import Main from 'layouts/Main';

import p1 from 'assests/Photos/ClassAssembly/2023/10E/1.jpg';
import p2 from 'assests/Photos/ClassAssembly/2023/10E/2.jpg';
import p3 from 'assests/Photos/ClassAssembly/2023/10E/3.jpg';
import p4 from 'assests/Photos/ClassAssembly/2023/10E/4.jpg';
import p5 from 'assests/Photos/ClassAssembly/2023/10E/5.jpg';
import p6 from 'assests/Photos/ClassAssembly/2023/10E/6.jpg';
import p7 from 'assests/Photos/ClassAssembly/2023/10E/7.jpg';
import p8 from 'assests/Photos/ClassAssembly/2023/10E/8.jpg';
import p9 from 'assests/Photos/ClassAssembly/2023/10E/9.jpg';
import p10 from 'assests/Photos/ClassAssembly/2023/10E/10.jpg';

import { Typography } from '@mui/material';
import Container from 'components/Container';
import SidebarArticles from 'views/ClassAssembly3E/components/SidebarArticles/SidebarArticles';
import ClassAssembly2023 from 'views/ClassAssembly2023/ClassAssembly2023';

const ClassAssembly10E2023 = () => {
  const theme = useTheme();
  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);

  const openLightbox = (index) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  };

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };

  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  const photos = [
    {
      src: p1,
      source: p1,
      rows: 1,
      cols: 1,
    },
    {
      src: p2,
      source:p2,
      rows: 1,
      cols: 1,
    },
    {
        src: p3,
        source:p3,
        rows: 1,
        cols: 1,
      },
      {
        src: p4,
        source:p4,
        rows: 1,
        cols: 1,
      },
      {
        src: p5,
        source:p5,
        rows: 1,
        cols: 1,
      },
      {
        src: p6,
        source:p6,
        rows: 1,
        cols: 1,
      },
      {
        src: p7,
        source:p7,
        rows: 1,
        cols: 1,
      },
      {
        src: p8,
        source:p8,
        rows: 1,
        cols: 1,
      },
      {
        src: p9,
        source:p9,
        rows: 1,
        cols: 2,
      },
      {
        src: p10,
        source:p10,
        rows: 1,
        cols: 2,
      },



    // {
    //   src: 'https://assets.maccarianagency.com/backgrounds/img21.jpg',
    //   source: 'https://assets.maccarianagency.com/backgrounds/img21.jpg',
    //   rows: 1,
    //   cols: 1,
    // },
  ];

  return (
    <Main>
        <Container>
    <Grid container spacing={4}>
            <Grid item xs={12} md={8}>
    <Box>
        
      <Box >
      <Typography variant='h4' align='center'>
      NUTRITION AND WELL-BEING
        </Typography>
        <Typography
        fontSize = "x-small"
        ><br/><br/>
        CLASS: 10E   &  Date: 14 & 16 JULY 2023
        </Typography>

        <br />
        <Typography variant={'subtitle1'} align={'justify'}>
        The students of class 10 E of National Public School, Yeshwanthpur presented their class assembly on the topic "Nutrition and Well-being”. On Day 1, students showcased the contrast between a healthy lifestyle involving a refined diet and an unhealthy one through a graceful mime, followed by a song emphasizing the style of person’s lifestyle. The song was written and composed by the students. On Day 2, students performed a skit illustrating how detrimental certain food combinations can be to one’s health and debunking misconceptions about certain food habits. The assembly concluded with an encapsulation of the entire grade’s performances on their SEWA theme “Nutrition and Hygiene” and the values learnt from each of them.
        </Typography>
      </Box>
      <Typography
          variant={'h6'}
          color={'primary'}
          align={'center'}
          fontWeight={'normal'}
        ><br/>
   “Unlock your Potential with good Nutrition”
<br/>
        </Typography>

      <Box>
        <ImageList
          variant="quilted"
          cols={2}
          rowHeight={isMd ? 300 : 200}
          gap={isMd ? 16 : 4}
        >
          {photos.map((item, i) => (
            <ImageListItem key={i} cols={item.cols} rows={item.rows}>
              <LazyLoadImage
                height={'100%'}
                width={'100%'}
                src={item.src}
                alt="..."
                effect="blur"
                onClick={() => openLightbox(i)}
                style={{
                  objectFit: 'cover',
                  filter:
                    theme.palette.mode === 'dark' ? 'brightness(0.7)' : 'none',
                  cursor: 'poiner',
                  borderRadius: 8,
                }}
              />
            </ImageListItem>
          ))}
        </ImageList>
      </Box>
      {viewerIsOpen && (
        <Lightbox
          mainSrc={photos[currentImage].src}
          nextSrc={photos[(currentImage + 1) % photos.length].src}
          prevSrc={
            photos[(currentImage + photos.length - 1) % photos.length].src
          }
          onCloseRequest={() => closeLightbox()}
          onMovePrevRequest={() =>
            setCurrentImage((currentImage + photos.length - 1) % photos.length)
          }
          onMoveNextRequest={() =>
            setCurrentImage((currentImage + 1) % photos.length)
          }
          reactModalStyle={{ overlay: { zIndex: 1500 } }}
        />
      )}
    </Box>
    </Grid>
    <Grid item xs={12} md={4}>
             
             <Box marginBottom={4}>
               <ClassAssembly2023 />
             </Box>
          
           {/* <SidebarNewsletter /> */}
         </Grid>
    </Grid> 
    </Container>
    </Main>
   
  );
};

export default ClassAssembly10E2023;